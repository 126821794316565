<template>
  <div id="page-user-view">
    <div>
      <vx-card title="Account" class="mb-base mt-4">
        <template slot="actions">
          <feather-icon
            @click="editClick"
            color="primary"
            type="border"
            icon="EditIcon"
            svgClasses="w-6 h-6 text-grey"
          ></feather-icon>
        </template>

        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <vs-avatar
                :src="
                  this.previewAvatarImage.src !== ''
                    ? this.previewAvatarImage.src
                    : activeUserInfo.photoURL
                "
                size="70px"
                class="mr-4 mb-4"
              />
              <!-- <img src="@/assets/images/pages/user.png" class="rounded w-full"  /> -->
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">TSA ID</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Title</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">First name</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Middle name</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Surname</td>
                <td></td>
              </tr>
              <!-- <tr>
                <td class="font-semibold">Date of birth</td>
                <td></td>
              </tr> -->
              <tr>
                <td class="font-semibold">Gender</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>
                  <vx-tooltip color="primary">
                    <span
                      style="
                        white-space: nowrap;
                        max-width: 12vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                      "
                    ></span>
                  </vx-tooltip>
                </td>
              </tr>

              <tr>
                <td class="font-semibold">Mobile number</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Business number</td>
                <td></td>
              </tr>

              <tr>
                <td class="font-semibold">Company</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Areas of interest</td>
                <td></td>
              </tr>
            </table>
          </div>

          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Main address</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Shipping address</td>
                <td></td>
              </tr>

              <tr>
                <td class="font-semibold">State</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Country</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Type of organisation</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Role</td>
                <td class="font-semibold text-success">MEMBER</td>
              </tr>

              <tr>
                <td class="font-semibold">Member type</td>
                <td class="font-semibold text-success"></td>
              </tr>
              <tr>
                <td class="font-semibold">Member sub-type</td>
                <td class="font-semibold text-danger"></td>
              </tr>

              <tr>
                <td class="font-semibold">Status</td>
                <td class="font-semibold text-success"></td>
              </tr>
              <tr>
                <td class="font-semibold">Membership since</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Paid through</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Membership delivery</td>
                <td class="ml-3">
                  <div class="ml-3"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Profile Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Birth Date</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Gender</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Mobile</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Fax</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Postal Code</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Work Phone</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Home Phone</td>
                <td></td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Membership Information" class="mb-base">
            <table class="mt-1 pt-1">
              <tr>
                <td class="font-semibold">Member Type</td>
                <td class="font-semibold text-success"></td>
              </tr>
              <tr>
                <td class="font-semibold">Membership Since</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Renewed Date</td>
                <td></td>
              </tr>

              <tr>
                <td class="font-semibold">Date Added</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Last Update</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Paid Through</td>
                <td></td>
              </tr>
              <tr>
                <td class="font-semibold">Last Updated By</td>
                <td></td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      name: this.$store.state.auth.userData.name,
      email: this.$store.state.auth.userData.email,
      university: this.$store.state.auth.userData.university,
      updateAvatarImage: '',
      previewAvatarImage: { src: '' }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    }
  },
  methods: {
    editClick () {
      this.$router.push('/user/settings')
    },
    uploadImage () {
      this.$refs.imageInputProfile.click()
    },
    onImageChange (e) {
      this.updateAvatarImage = e.target.files[0]
      const fr = new FileReader()
      fr.onload = (function (previewAvatarImage) {
        return function (e) {
          previewAvatarImage.src = e.target.result
        }
      })(this.previewAvatarImage)
      fr.readAsDataURL(this.updateAvatarImage)
    },
    submit () {
      this.$store.dispatch('auth/updateUserProfile', {
        name: this.name,
        email: this.email,
        updateAvatarImage: this.updateAvatarImage,
        notify: this.$vs.notify
      })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>
